import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Toast, ToastType } from '@shared/models/toast.model';

@Injectable({
  providedIn: 'root',
})
export class AlertifyService {
  toasts$ = new Subject<Toast>();

  positive(message: string, ttl?: number) {
    this.createMessage(message, 'positive', ttl);
  }

  negative(message: string, ttl?: number) {
    this.createMessage(message, 'negative', ttl);
  }

  notice(message: string, ttl?: number) {
    this.createMessage(message, 'notice', ttl);
  }

  informative(message: string, ttl?: number) {
    this.createMessage(message, 'informative', ttl);
  }

  createMessage(message: string, type: ToastType, ttl = 30) {
    this.toasts$.next({
      id: Math.random().toString(),
      type,
      message,
      ttl,
    });
  }
}
